import React from 'react';
import {
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import tableHeaderStyle from '../../style';


// Table header and sorting
const TableHeader = (props) => {
    const { valueToOrderBy, orderDirection, setOrderDirection, setValueToOrderBy } = props;
    const { t } = useTranslation();

    const headers = ["taskId", "date", "type", "species", "status"];
    const headerNames = ["id", t("date"), t("type"), t("species"), t("status")];

    // Handle sorting request
    const handleRequestSort = (property) => {
        const isAscending = (valueToOrderBy === property && orderDirection === 'asc');
        setValueToOrderBy(property);
        setOrderDirection(isAscending ? 'desc' : 'asc');
    }

    return (
        <TableHead>
            <TableRow sx={{ backgroundColor: 'primary.bluegrey' }}>
                {headers.map((header, i) => {
                    return (
                        <TableCell key={header} align='right'>
                            <TableSortLabel
                                active={valueToOrderBy === header}
                                direction={valueToOrderBy === header ? orderDirection : 'asc'}
                                onClick={() => handleRequestSort(header)}
                                sx={tableHeaderStyle}
                            >
                                {headerNames[i]}
                            </TableSortLabel>
                        </TableCell>
                    )
                })}
                <TableCell align='right' sx={{ color: 'primary.light3' }} >
                    {t("action")}
                </TableCell>
            </TableRow>
        </TableHead>
    )
}

export default TableHeader;