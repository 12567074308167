import {
    Collapse,
    Box,
    Typography,
    Card,
    CardContent,
    CardMedia,
    CardActions,
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import MediaCardActions from './MediaCardActions';
import { getUrl } from '../../tools/functions';
import 'moment/locale/fi'
import Viewpoint from './Viewpoint';

export default function MediaCard(props) {
    const { img, index, images, setImages, checked, setChecked, species, sealID } = props;
    const [show, setShow] = useState(false);
    const { t } = useTranslation();
    const [viewpoints, setViewpoints] = useState({
        'right':[img.viewpoints.right, t("right")], 
        'left':[img.viewpoints.left,  t("left")], 
        'up':[img.viewpoints.up,  t("up")], 
        'down': [img.viewpoints.down,  t("down")],
        'front':[img.viewpoints.front,  t("front")], 
        'back': [img.viewpoints.back,  t("back")]
    });
    const handleShowOptions = () => setShow(!show);

   

    const sumValues = obj => Object.values(obj).reduce((acc, x) => acc + x[0], 0);

    return (
        <React.Fragment>
            <Card sx={{
                m: '0.2%',
                p: '0.2%',
                display: 'flex',
                flexDirection: 'column',
                width: { xs: '49.2%', md: '19.2%' },
                cursor: 'pointer',
                '&:hover': {
                    backgroundColor: 'primary.light3'
                },
                
                ...(sumValues(viewpoints) === 0 && {
                    backgroundColor: 'red.light',
                    '&:hover': {
                        backgroundColor: 'red.light'
                    }
                }), 
                
                
            }}
                onClick={handleShowOptions}>
                <CardContent sx={{ mb: "auto", position: 'relative' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', position: 'relative', height: '2em' }}>
                        <Typography sx={{ width: '40%', overflow: 'hidden', textOverflow: 'ellipsis', height: '1.3em', whiteSpace: 'nowrap', position: 'absolute',}}>
                            {img.image.split("/").at(-1)}
                        </Typography>
                        <Typography name={img.date} sx={{ width: '60%', textAlign: 'end', position: 'absolute', right: 0 }}>
                            {(new Date(img.date).toLocaleDateString(t("locale") + '-fi', { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC'}))}
                        </Typography>
                    </Box>
                    <Box sx={{ position: 'relative', width: '100%', maxWidth: '100%' }}>
                        <CardMedia
                            component="img"
                            sx={{ position: 'relative', width: '100%', maxWidth: '100%' }}
                            data-id={img.id}
                            image={getUrl() + "/database/" + species + "/" + sealID + "/" + img.image}
                            alt={img.image.split(species)[1]} >
                        </CardMedia>
                    </Box>
                    <Viewpoint id={img.id}
                                viewpoints={viewpoints}
                                setViewpoints = {setViewpoints}
                    />
                </CardContent>

                <Collapse in={show} timeout="auto" unmountOnExit>
                    <CardActions sx={{ justifyContent: 'space-between' }}>
                        <MediaCardActions
                            img={img}
                            index={index}
                            images={images}
                            setImages={setImages}
                            checked={checked}
                            setChecked={setChecked}
                            species={species}
                            sealID = {sealID}
                        />
                    </CardActions>
                </Collapse>
            </Card>
        </React.Fragment>
    )
}