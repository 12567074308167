import React from 'react';
import {
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import tableHeaderStyle from '../../style';

// Table Header. Uses functions to handle data sorting
const TableHeader = (props) => {
    const { valueToOrderBy, orderDirection, setOrderDirection, setValueToOrderBy } = props;
    const { t } = useTranslation();

    // Handle sorting request
    const handleRequestSort = (property) => {
        const isAscending = (valueToOrderBy === property && orderDirection === 'asc');
        setValueToOrderBy(property);
        setOrderDirection(isAscending ? 'desc' : 'asc');
    }

    return (
        <TableHead>
            <TableRow sx={{ backgroundColor: 'primary.bluegrey' }}>
                <TableCell />
                <TableCell key="id" align="left">
                    <TableSortLabel
                        sx={tableHeaderStyle}
                        active={valueToOrderBy === 'SealID'}
                        direction={valueToOrderBy === 'SealID' ? orderDirection : 'asc'}
                        onClick={() => handleRequestSort("SealID")}
                    >
                        {t("sealID")}
                    </TableSortLabel>
                </TableCell>
                <TableCell key="name">
                    <TableSortLabel
                            sx={tableHeaderStyle}
                            active={valueToOrderBy === 'name'}
                            direction={valueToOrderBy === 'name' ? orderDirection : 'asc'}
                            onClick={() => handleRequestSort("name")}
                        >
                        {t("sealName")}
                    </TableSortLabel>
                </TableCell>
                <TableCell key="gender">
                    <TableSortLabel
                            sx={tableHeaderStyle}
                            active={valueToOrderBy === 'gender'}
                            direction={valueToOrderBy === 'gender' ? orderDirection : 'asc'}
                            onClick={() => handleRequestSort("gender")}
                        >
                        {t("sealGender")}
                    </TableSortLabel>
                </TableCell>
                <TableCell key="count">
                    <TableSortLabel
                        sx={tableHeaderStyle}
                        active={valueToOrderBy === 'images'}
                        direction={valueToOrderBy === 'images' ? orderDirection : 'asc'}
                        onClick={() => handleRequestSort("images")}
                    >
                        {t("imageCount")}
                    </TableSortLabel>
                </TableCell>
            </TableRow>
        </TableHead>
    )
}

export default TableHeader;