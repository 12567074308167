import React, { useState } from 'react';
import {
    Box,
    IconButton,
    Radio,
    FormControlLabel,
    RadioGroup,
    Tooltip,
    Select,
    MenuItem,
    InputLabel,
    FormControl
} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';

import { useTranslation } from 'react-i18next';

import CustomModal from '../../tools/Modal'
import Info from './Info';
import UploadViewpoint from './UploadViewpoint';
import Gender from '../../pages/Database/Gender';


// Upload settings 
const UploadSettings = (props) => {
    const { radioValue, setRadioValue, database, setDatabase, gender, setGender, viewpoint } = props;
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);
    // const error = [top, right, left, bottom].filter((v) => v).length == 0;



    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: "20px 0 20px" }}>
            <RadioGroup
                row
                value={radioValue}
                onChange={(e) => setRadioValue(e.target.value)}
                sx={{ width: { xs: '100%', sm: '97%' } }}
            >
                <FormControlLabel value="Full" control={<Radio />} label={t("segmentatiotAndReID")} sx={{ p: 1, maxWidth: { xs: 180 } }} />
                <FormControlLabel value="Reidentification" control={<Radio />} label={t("re-identification")} sx={{ p: 1 }} />
                <FormControlLabel value="Segmentation" control={<Radio />} label={t("segmentation")} sx={{ p: 1 }} />
                <Tooltip title={t("info")}>
                    <IconButton variant="raised" sx={{ color: 'black', m: 'auto' }} onClick={() => setOpen(true)}>
                        <HelpIcon />
                    </IconButton>
                </Tooltip>
                <CustomModal open={open} setOpen={setOpen} inside={<Info />} />
            </RadioGroup>
            {radioValue === "Full" || radioValue === "Reidentification" ? (
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', width: '100%'}}>
                    <FormControl variant="standard" sx={{ width: { xs: '100%', sm: '60%' }, margin: "0 10px 20px"}}>
                        <InputLabel id="demo-select-small">Database</InputLabel>
                        <Select
                            labelId="demo-select-small"
                            value={database}
                            label="Database"
                            onChange={e => (setDatabase(e.target.value))}
                        >
                            <MenuItem value={"norppa"}>Norppa</MenuItem>
                            <MenuItem value={"unknown"}>Unknown</MenuItem>

                        </Select>
                    </FormControl>
                    <Gender  
                        gender={gender} 
                        handleGenderChange = {(e) => setGender(e.target.value)}
                        isHelper
                    /> 
                    <UploadViewpoint viewpoint={viewpoint}
                    />
                </Box>
            ) : <></>
            }
            
        </Box>
    )
}

export default UploadSettings;