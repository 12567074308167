import React, { useState, Suspense } from 'react';
import {
    Box,
    Container,
    Divider,
    TextField,
    Radio,
    RadioGroup,
    FormControlLabel
} from '@mui/material';

import UploadHeader from '../Upload/UploadHeader';
import UploadDropzone from '../Upload/UploadDropzone';
import UploadSubmit from '../Upload/UploadSubmit';
import UploadViewpoint from '../Upload/UploadViewpoint';
import Gender from './Gender'
import { useTranslation } from 'react-i18next';


// Main upload container
const Upload = (props) => {

    const { sealID, sealName, sealGender, species} = props;
    const [files, setFiles] = useState([]);
    const [segmented, setSegmented] = useState(false);
    const [newSealID, setNewSealID] = useState("");
    const [newSealName, setNewSealName] = useState("");
    const [newSealGender, setNewSealGender] = useState("n");
    const [right, setRight] = useState(false);
    const [left, setLeft] = useState(false);
    const [up, setUp] = useState(false);
    const [down, setDown] = useState(false);
    const [front, setFront] = useState(false);
    const [back, setBack] = useState(false);
    const idStart = species === "norppa" ? "phs" : "unk";
    const { t } = useTranslation();

    return (
        <Container
            sx={{
                boxShadow: 1,
                borderRadius: 2,
                p: 1,
                width: '90%',
                mx: 'auto'
            }}
        >
            <Box sx={{
                margin: "20px 0",
            }}>
                <UploadHeader files={files} setFiles={setFiles} />
                <Divider />
                <UploadDropzone
                    files={files}
                    setFiles={setFiles}
                />
                {sealID ?  
                    null : 
                    <TextField 
                        required 
                        value={newSealID} 
                        label={t("sealID")} 
                        variant="standard" 
                        sx={{margin: "20px 10px 0"}}
                        helperText={t("sealIDStart") + idStart}
                        onChange={(e) => setNewSealID(e.target.value)}
                    /> 
                }


                {sealName ?  
                    null : 
                    <TextField  
                        value={newSealName} 
                        label={t("Seal Name")} 
                        variant="standard" 
                        sx={{margin: "10px 10px 10px"}}
                        onChange={(e) => setNewSealName(e.target.value)}
                    /> 
                }


                {sealGender ?  
                    null : 
                    <div>
                        <Gender  
                            gender={newSealGender} 
                            handleGenderChange = {(e) => setNewSealGender(e.target.value)}
                            isHelper
                        /> 
                    </div>
                                        
                }

                

                
                <RadioGroup
                    row
                    defaultValue={false}
                    name="segmented"
                    value={segmented}
                    onChange={(e) => setSegmented(e.target.value)}
                    sx={{padding: '0 10px', margin: '15px 0 0'}}
                >
                    <FormControlLabel value={false} control={<Radio />} label={t("rawImages")} />
                    <FormControlLabel value={true} control={<Radio />} label={t("segmentedImages")} />
                </RadioGroup>
                <UploadViewpoint viewpoint={{'right':[right, setRight, t("right")], 
                        'left':[left, setLeft, t("left")], 
                        'up':[up, setUp, t("up")], 
                        'down': [down, setDown, t("down")],
                        'front': [front, setFront, t("front")],
                        'back': [back, setBack, t("back")],
                        }
                    } />
            </Box>

            < UploadSubmit
                files={files}
                radioValue={"Upload"}
                params={{"seal_type": species, "seal_id": sealID ? sealID : newSealID, "seal_gender": sealGender ? sealGender : newSealGender, "seal_name": sealName ? sealName : newSealName, "segmented": segmented, "viewpoints": {"right": right, "left": left, "up": up, "down": down, "front": front, "back": back}}}
            />
            
        </Container>
    );
}

export default function App(props) {
    const { sealID, species, sealName, sealGender } = props;
    return (
        <Suspense fallback="loading">
            <Upload sealID={sealID} sealGender={sealGender} species={species} sealName= {sealName}/>
        </Suspense>
    )
}