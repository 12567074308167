const tableHeaderStyle = {
    '&.MuiTableSortLabel-root': {
        color: 'primary.light3',
    },
    '&.MuiTableSortLabel-root:hover': {
        color: 'primary.light3',
    },
    '&.Mui-active': {
        color: 'primary.light3',
    },
    '& .MuiTableSortLabel-icon': {
        color: '#f3f9ff !important',
    },
}

export default tableHeaderStyle;