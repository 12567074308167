import React, { useState, Suspense } from 'react';
import {
    AppBar,
    Toolbar,
    IconButton,
    Box,
    Container,
    Button,
    Drawer,
    Divider,
    Link,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { ThemeProvider } from '@mui/material/styles';

import NavigationButtons from './NavigationButtons';
import theme from './theme';
import style from './style';

// Main component 
const Navbar = () => {
    const [burger, setBurger] = useState(false);
    const toggleDrawer = (open) => (event) => {
        setBurger(open);
    };
    return (

        <AppBar sx={{
            // height: 'auto',
            // position: 'static',
            // width: '100%',
            // display: 'flex',
            backgroundColor: 'primary.light3',
            minHeight: '75px',
            justifyContent: 'center',
        }}>
            <Container>
                <Toolbar
                    sx={{ justifyContent: 'space-between' }}>
                    <ThemeProvider theme={theme}>
                        <Link
                            href="/#"
                            sx={{
                                textDecoration: 'none',
                                color: 'background.grey',
                            }}>
                            <Button
                                sx={[style, { p: "20px 0px 20px 75px" }]}
                            >
                                <img src={process.env.PUBLIC_URL + "/Logo.png"} className='logo' alt='logo' />
                                NorppaID
                            </Button>
                        </Link>

                        {/* Navigation buttons for desktop */}
                        <Box sx={{ display: { xs: "none", sm: "flex" } }}>
                            <NavigationButtons setBurger={setBurger} />
                        </Box>

                        {/* Burger button */}
                        <IconButton
                            onClick={() => setBurger(!burger)}
                            sx={{
                                display: { xs: "flex", sm: "none" },
                                '&:hover': { color: 'primary.main' },
                                // transition: 'all 375ms ease',
                            }}
                        >
                            <MenuIcon />
                        </IconButton>

                        {/* Navigation buttons for mobile */}
                        <Drawer
                            PaperProps={{
                                sx: { height: 220, justifyContent: 'top' },
                            }}
                            open={burger}
                            anchor='top'
                            onClose={toggleDrawer(false)}
                        >
                            <Box
                                sx={{
                                    mx: 'auto',
                                }}
                            >
                                <Link
                                    href="/#"
                                    sx={{
                                        textDecoration: 'none',
                                        color: 'background.grey',
                                        textAlign: 'center'
                                    }}>
                                    <Button
                                        sx={style}
                                        className="logoButton"
                                    >
                                        <img src={process.env.PUBLIC_URL + "/Logo.png"} className='Mobilelogo' alt='logo' />
                                        NorppaID
                                    </Button>
                                </Link>
                            </Box>
                            <Divider />
                            <Box
                                sx={{
                                    display: 'flex',
                                    // mx: 'auto',
                                    flexDirection: 'column',
                                    // justifyContent: 'space-evenly'
                                }}
                            >
                                <NavigationButtons setBurger={setBurger} />
                            </Box>
                        </Drawer>
                    </ThemeProvider>
                </Toolbar>
            </Container>
        </AppBar >
    );
};


export default function App() {
    return (
        <Suspense fallback="loading">
            <Navbar />
        </Suspense>
    )
}