import React, { useState } from 'react';
import {
    MenuItem,
    ClickAwayListener,
    Popper,
    Paper,
    Grow,
    MenuList,
} from '@mui/material';

import { useTranslation } from 'react-i18next';

// Language selector component
const LanguageSelector = (props) => {
    const { open, anchorRef, setOpen } = props;
    const { t, i18n } = useTranslation();

    const [language, setLanguage] = useState(i18n.language === "en" ? 1 : 2);

    const handleLanguageChange = (lang) => {
        setLanguage(lang)
        console.log(lang)
        if (lang === 1) {
            i18n.changeLanguage("en");
        } else {
            i18n.changeLanguage("fi");
        }
    }

    const handleClose = (event) => {
        if (event.target.value) {
            handleLanguageChange(event.target.value)
        }
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        } else if (event.key === 'Escape') {
            setOpen(false);
        }
    }

    return (
        <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            placement="bottom"
            transition
            disablePortal={true}
            style={{ zIndex: 5 }}
        >
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin:
                            placement === 'bottom-start' ? 'left top' : 'left bottom',
                    }}
                >
                    <Paper sx={{ px: 1 }}>
                        <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                                autoFocusItem={open}
                                onKeyDown={handleListKeyDown}
                            >
                                <MenuItem
                                    onClick={handleClose}
                                    value={1}
                                // disabled={(language === 1 ? true : false)}
                                >
                                    {t("english")}
                                </MenuItem>
                                <MenuItem
                                    onClick={handleClose}
                                    value={2}
                                // disabled={(language === 2 ? true : false)}
                                >
                                    {t("finnish")}
                                </MenuItem>
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    )
}

export default LanguageSelector;