import React from 'react';
import {
    FormControlLabel,
    FormControl,
    Checkbox,
    FormGroup, 
    FormHelperText,
    FormLabel
} from '@mui/material';

import { useTranslation } from 'react-i18next';


// Upload settings 
const UploadViewpoint = (props) => {
    const { viewpoint } = props;
    const { t } = useTranslation();
    

    return (
        <FormControl variant="standard" sx={{ width: '90%', margin: '20px 10px 0'}}>
            <FormLabel sx={{ textAlign: 'left' }} component="legend">{t('viewpoint')}</FormLabel>
            <FormGroup sx={{ display: 'flex', flexDirection: 'row'}}>
            {Object.entries(viewpoint).map(([key, [value, setValue, label]])=>
                <FormControlLabel
                    key={key}
                    control={
                    <Checkbox checked={value} onChange={e => (setValue(e.target.checked))} name={key} />
                    }
                    label={label}
                />
            )}
            
            </FormGroup>
            <FormHelperText>Pick at least one viewpoint</FormHelperText>
        </FormControl>
    ) 
}

export default UploadViewpoint;