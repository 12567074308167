import React from 'react';
import { Link as Linked } from 'react-router-dom'
import {
    Button,
    Link,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import style from './style';

import DatabaseMenu from './DatabaseMenu';
import LanguageSelector from './LanguageSelector';
// Component to hold Navbar buttons
const NavigationButtons = ({ setBurger }) => {
    const { t } = useTranslation();

    const anchorRefDatabase = React.useRef(null);
    const anchorRefLanguage = React.useRef(null);
    const [openDatabase, setOpenDatabase] = React.useState(false);
    const [openLanguage, setOpenLanguage] = React.useState(false);

    const handleDatabaseToggle = () => {
        setOpenDatabase(!openDatabase);
    };

    const handleLanguageToggle = () => {
        setOpenLanguage(!openLanguage);
    };

    return (
        <React.Fragment>
            <Link
                href="/#start"
                sx={{
                    textDecoration: 'none',
                    textAlign: 'center',
                    color: 'background.grey',
                }}>
                <Button
                    sx={style}
                    onClick={() => setBurger(false)}>
                    {t("submit")}
                </Button>
            </Link>

            <Button
                sx={style}
                ref={anchorRefDatabase}
                onClick={handleDatabaseToggle}
            >
                {t("database")}
            </Button>
            < DatabaseMenu
                anchorRef={anchorRefDatabase}
                setOpen={setOpenDatabase}
                open={openDatabase}
                setBurger={setBurger}
            />

            <Button
                component={Linked}
                to={"/tasks"}
                onClick={() => setBurger(false)}
                sx={style}>
                {t("tasks")}
            </Button>

            <Button
                sx={style}
                ref={anchorRefLanguage}
                onClick={handleLanguageToggle}
            >
                {t("language")}
                {openLanguage ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </Button>
            <LanguageSelector
                anchorRef={anchorRefLanguage}
                setOpen={setOpenLanguage}
                open={openLanguage}
            />
        </React.Fragment >
    )
}

export default NavigationButtons;