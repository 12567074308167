import React from 'react';
import {
    Box,
    Typography,
    IconButton,
    Tooltip,
} from '@mui/material';

import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from 'react-i18next';

// Small header on top of the upload container
const UploadHeader = (props) => {
    const { files, setFiles } = props

    const { t } = useTranslation();

    return (
        <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
            <Typography sx={{ flexGrow: 1, mx: 2, color: 'primary.dark1', fontWeight: 'bold' }} textAlign={'left'}>
                {t("uploadFiles")}
            </Typography>
            {files.length !== 0
                ?
                <Tooltip title={t("clearFiles")}>
                    <IconButton variant="raised" component="span" sx={{ color: 'primary.dark1' }} onClick={() => setFiles([])}>
                        <ClearIcon />
                    </IconButton>
                </Tooltip>
                :
                <></>
            }
            <Box>
                <input
                    style={{ display: "none" }}
                    id="contained-button-file"
                    type="file"
                    onChange={e => setFiles([...e.target.files])}
                    multiple
                />
                <label htmlFor="contained-button-file">
                    <Tooltip title={t("uploadFiles")}>
                        <IconButton variant="raised" component="span" sx={{ color: 'primary.dark1' }}>
                            <CloudUploadOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                </label>
            </Box>
        </Box>
    )
}

export default UploadHeader;