import React, { useState } from 'react';
import {
    Modal,
    Button,
    Typography,
    Box,
    Checkbox,
    FormControlLabel
} from '@mui/material';

import { sendRequest } from '../../tools/Fetch';
import useToken from '../../tools/useToken';

export default function MediaCard(props) {
    const { deleteModalOpen, setDeleteModalOpen, img, setChecked, setImages, index } = props;

    const { token } = useToken();
    const [checkedHolder, setCheckedHolder] = useState(false);
    const [loading, setLoading] = useState(false);

    

    const handleChange = (event) => {
        setCheckedHolder(event.target.checked);
    };

    const handleClick = (e) => {
        handleRemove(e, img.id);
    }

    const handleRemove = (e, id) => {
        e.stopPropagation();
        console.log("id: " + id);
        setLoading(true);
        sendRequest(("remove_image?image_id=" + id), 'POST', token)
            .then((data) => {
                setImages((images) => images.filter((_, i) => i !== index));
                setLoading(false);
                setChecked(checkedHolder);
            })
    }

    // handle

    return (
        <React.Fragment>
            {/* {console.log(img.id)} */}
            <Modal
                open={deleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
                onClick={(e) => e.stopPropagation()}
                sx={{ overflow: 'scroll' }}
            >
                <Box sx={{
                    position: 'absolute',
                    left: '50%',
                    top: '40%',
                    width: '20%',
                    maxWidth: '1000px',
                    minWidth: '250px',
                    transform: 'translate(-50%, 30%)',
                    bgcolor: 'background.paper',
                    boxShadow: 5,
                    borderRadius: 1,
                    p: 2,
                }}>
                    <React.Fragment>
                        <Typography textAlign={'center'}>
                            Are you sure you want to delete this image?
                        </Typography>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checkedHolder}
                                    onChange={handleChange}
                                />
                            }
                            label="Do not show this again" />
                        <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>

                            <Button  disabled={loading} variant="contained" onClick={handleClick}>
                                Yes
                            </Button>
                            <Button variant="contained" color="red" onClick={() => setDeleteModalOpen(0)}>
                                no
                            </Button>
                        </Box>
                    </React.Fragment>
                </Box>
            </Modal>
        </React.Fragment >
    )
}