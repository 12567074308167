import React, { Suspense, useEffect, useState } from 'react';
import {
    Container,
    Box,
    CircularProgress
} from '@mui/material';

import ParticleBackground from '../../ParticleBackground';
import TableContent from './TableContent';
import { fetchData } from '../../tools/Fetch';
import useToken from '../../tools/useToken';

// Main container
const Tasks = () => {
    const [data, setData] = useState([]);
    const { token, removeToken } = useToken();

    // Causes syntax error 
    useEffect(() => {
        fetchData("get_tasks", 'GET', token, removeToken, setData)
            .then(data => {
                setData(data);
                // console.log(data)
            })
            .catch(err => console.error(err));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Box sx={{
            minHeight: 'calc(100vh - 183px)',
            width: '100%',
            marginTop: '80px',
            display: 'flex',
        }}>
            <Container sx={{ zIndex: 1 }}>
                <TableContent data={data} />
            </Container>
            <ParticleBackground />
        </Box>
    );
}

export default function App() {
    return (
        <Suspense fallback={<CircularProgress />}>
            <Tasks />
        </Suspense>
    )
}