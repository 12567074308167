import React, { useState, Suspense } from 'react';
import {
    Box,
    Container,
    TextField,
    Button,
    Typography
} from '@mui/material';

import CircularProgress from '@mui/material/CircularProgress';
import SendIcon from '@mui/icons-material/Send';

import useToken from '../../tools/useToken';
import { fetchData, sendRequest } from '../../tools/Fetch';
import { useTranslation } from 'react-i18next';


// Main upload container
const Move = (props) => {

    const { sealID, sealName, setModalOpen, setData} = props;
    const [newSealID, setNewSealID] = useState("");
    const [error, setError] = useState(false);
    const [clicked, setClicked] = useState(false);
    const [newSealName, setNewSealName] = useState(sealName);
    const idStart = "phs";
    const { t } = useTranslation();

    const { token, removeToken } = useToken();

    const [sent, setSent] = useState(false);

    const handleClick = () => {
        if ((newSealID === "") || (!newSealID.startsWith('phs'))) {
            setError(true);
            setClicked(true);
        } else {
            setError(false);
            setSent(true)
            sendRequest(("move_unknown?unknown_seal_id=" + sealID + "&main_seal_id=" + newSealID + "&main_seal_name=" + newSealName), 'POST', token)
            .then(() => {
                setModalOpen(false);
                fetchData("get_database?seal_type=unknown", 'GET', token, removeToken, setData)
                .then(data => {
                    setData(data);
                })
                .catch(err => console.error(err));
            });
        }
    }

    const handleChange = (e) => {
        if (clicked) {
            if((newSealID === "") || (!newSealID.startsWith('phs'))) {
                setError(true);
            } else {
                setError(false);
            }
        }
        
        setNewSealID(e.target.value);

    }

    return (
        <Container
            sx={{
                boxShadow: 1,
                borderRadius: 2,
                p: 1,
                width: '90%',
                mx: 'auto'
            }}
        >
            <Box sx={{
                margin: "20px 0",
            }}>
                <Typography variant="h5" component="h5"  sx={{margin: "20px 10px"}}>
                    {t("oldSealID")} {sealID}
                </Typography>
                <Typography variant="body2" sx={{margin: "20px 10px"}}>
                    If the seal already exists in the main database, the new images will be added to it automatically.
                </Typography>
                
                <TextField 
                    required
                    error = {error}
                    value={newSealID} 
                    label={t("newSealID")} 
                    variant="standard" 
                    sx={{margin: "20px 10px"}}
                    helperText={t("sealIDStart") + idStart}
                    onChange={(e) => handleChange(e)}
                /> 
                
                <TextField  
                    value={newSealName} 
                    label={t("newSealName")} 
                    variant="standard" 
                    sx={{margin: "20px 10px"}}
                    onChange={(e) => setNewSealName(e.target.value)}
                /> 
                

            </Box>

            <Button
                variant="contained"
                size="large"
                sx={{ display: 'flex', margin: '0 10px 15px'}}
                onClick={handleClick}>
                {sent
                    ?
                    <CircularProgress size={30} sx={{ color: 'primary.dark1' }} />
                    :
                    <React.Fragment>
                        <Typography>
                            {t("move")}
                        </Typography>
                        <SendIcon sx={{ ml: 1 }} />
                    </React.Fragment>
                }
            </Button>
            
        </Container>
    );
}

export default function App(props) {
    const { sealID, species, sealName, setData, setModalOpen } = props;
    return (
        <Suspense fallback="loading">
            <Move sealID={sealID} species={species} sealName= {sealName} setData = {setData} setModalOpen={setModalOpen}/>
        </Suspense>
    )
}