import React from 'react';
import {
   Typography,
   Divider,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

// Different procedure information
export default function Info() {
   const { t } = useTranslation();
   return (
      <React.Fragment >
         <Typography variant="h4" sx={{ m: 0.5 }}>
            {t("segmentatiotAndReID")}
         </Typography>
         <Divider />
         <Typography sx={{ mb: 1, ml: 1 }}>
            {t("segmentatiotAndReIDInstruction")}
         </Typography>

         <Typography variant="h4" sx={{ m: 0.5 }}>
            {t("segmentation")}
         </Typography>
         <Divider />
         <Typography sx={{ mb: 1, ml: 1 }}>
            {t("segmentationInstruction")}

         </Typography>
         <Typography variant="h4" sx={{ m: 0.5 }}>
            {t("re-identification")}
         </Typography>
         <Divider />
         <Typography sx={{ mb: 1, ml: 1 }}>
            {t("re-identificationInstruction")}
         </Typography>
      </React.Fragment>
   )
}