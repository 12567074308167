import React, { useState } from 'react';
import {
    Typography,
    Table,
    Button,
    TableBody,
    TableContainer,
    Paper,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { sortedRowInformation, getComparator, filterDBData } from '../../tools/functions';
import { useTranslation } from 'react-i18next';
import TableHeader from './TableHeader';
import Pagination from './Pagination';
import Row from './TableRow';
import DBModal from './DBModal';
import DBUpload from './DBUpload';
import TableFiltersForm from '../../tools/TableFiltersForm';


// Main container for the table.
const TableContent = (props) => {
    const { data, setData, species } = props;

    const [orderDirection, setOrderDirection] = useState('desc');
    const [valueToOrderBy, setValueToOrderBy] = useState('SealID');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [showUploadModal, setShowUploadModal] = useState(false);
    const viewpoints = ['right', 'left', 'down', 'up', 'front', 'back', 'unknown'];
    const genders = ['male', 'female', 'unknown'];

    const [selectedViewpoints, setSelectedViewpoints] = useState(viewpoints);
    const [selectedGenders, setSelectedGenders] = useState(genders);
    
    const { t } = useTranslation();
    

    const handleModalOpen = (e) => {
        e.stopPropagation();
        setShowUploadModal(true);
    }

    return (
        <React.Fragment >
            <Typography variant='h3' sx={{ color: 'primary.dark1', my: 5, }}>
                {(species === "norppa" ? "Saimaa" : species.charAt(0).toUpperCase() + species.slice(1))} ringed seal database
            </Typography>
            <Paper
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    alignItems: 'center',
                    p: 1,
                    mt: 3
                }}
            >
                <Typography
                    variant='h4'
                    color='primary.bluegrey'
                    sx={{ mx: 5 }}
                >
                    {t("TasksFilters")}
                </Typography>
                <TableFiltersForm name={"viewpoint"} list={viewpoints} selected={selectedViewpoints} setSelected={setSelectedViewpoints} />
                <TableFiltersForm name={"gender"} list={genders} selected={selectedGenders} setSelected={setSelectedGenders} />
                <Button variant="contained"  color="success" onClick={handleModalOpen} startIcon={<AddIcon />} sx={{ margin: '20px 30px 20px 30px', minHeight: '55px', minWidth: { xs: '100%', sm: '160px' } }}>
                    {t("uploadNew")}
                </Button>
            </Paper>
            
            <DBModal open={showUploadModal} setOpen={setShowUploadModal} >
                <DBUpload species={species} />
            </DBModal>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 350 }}>
                    <TableHeader
                        valueToOrderBy={valueToOrderBy}
                        setValueToOrderBy={setValueToOrderBy}
                        orderDirection={orderDirection}
                        setOrderDirection={setOrderDirection}
                    />

                    <TableBody>
                        {
                            sortedRowInformation(filterDBData(data, selectedViewpoints, selectedGenders), getComparator(orderDirection, valueToOrderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => (
                                    <Row key={index} id={index} row={row} species={species} setData={setData}/>
                                ))
                        }
                    </TableBody>

                </Table>
            </TableContainer>
            <Pagination
                data={data}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
            />
        </React.Fragment >
    )
}

export default TableContent;