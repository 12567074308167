import React, { useState } from 'react';
import {
    IconButton,
    Button,
    Tooltip,
} from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import CustomModal from '../../tools/Modal';
import useToken from '../../tools/useToken';
import { getUrl } from '../../tools/functions';
import { sendRequest } from '../../tools/Fetch';
import MediaCardActionModal from './MediaCardActionsModal';

export default function MediaCard(props) {
    const { img, index, setImages, checked, setChecked, species, sealID } = props;

    const { t } = useTranslation();
    const { token } = useToken();

    const [imageModalOpen, setImageModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    const handleImageModalOpen = (e) => {
        e.stopPropagation();
        setImageModalOpen(true);
    }

    const handleDeleteModalOpen = (e) => {
        e.stopPropagation();
        if (!checked) {

            setDeleteModalOpen(true);
        } else {
            handleRemove(e, img.id)
        }
    }

    const handleRemove = (e, id) => {
        e.stopPropagation();
        sendRequest(("remove_image?image_id=" + id), 'POST', token)
            .then(() => {
                setImages((images) => images.filter((_, i) => i !== index));
            })
    }

    return (
        <React.Fragment>
            {/* {console.log(img)} */}
            <Button
                size="small"
                onClick={handleImageModalOpen}
                sx={{
                    color: 'primary.main',
                    fontWeight: 'bold'
                }}
            >
                {t("openImage")}
            </Button>
            <CustomModal
                open={imageModalOpen}
                setOpen={setImageModalOpen}
                inside={
                    <img
                        src={getUrl() + "/database/" + species + "/" + sealID + "/" + img.image}
                        alt={img.image.split(species)[1]}
                        style={{ position: 'relative', margin: 'auto', width: '100%' }} />
                }
            />
            <Tooltip title={t("delete")}>
                <IconButton
                    size="small"
                    onClick={handleDeleteModalOpen}
                >
                    <DeleteIcon />
                </IconButton>
            </Tooltip>
            {checked ? <></> :
                <MediaCardActionModal
                    deleteModalOpen={deleteModalOpen}
                    setDeleteModalOpen={setDeleteModalOpen}
                    index={index}
                    img={img}
                    setChecked={setChecked}
                    setImages={setImages}
                    handleRemove={handleRemove}
                />
            }

        </React.Fragment >
    )
}