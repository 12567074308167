import React from 'react';
import {
    FormControlLabel,
    FormControl,
    Checkbox,
    FormGroup
} from '@mui/material';
import { sendRequest } from '../../tools/Fetch';
import useToken from '../../tools/useToken';


export default function Viewpoint(props) {
    const { id, viewpoints, setViewpoints } = props;
    const { token } = useToken();

    const handleUpdateViewpoint = (e, value, viewpoint) => {
        e.stopPropagation();
        sendRequest(("update_viewpoint?image_id=" + id + "&viewpoint=" + viewpoint), 'POST', token)
            .then(() => {
                const new_viewpoints = {...viewpoints}
                new_viewpoints[viewpoint][0] = !value
                setViewpoints(new_viewpoints)
            })
    }    

    return (
        <FormControl variant="standard" sx={{ width: '100%', margin: '10px 0', position: 'relative'}}>
            <FormGroup sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', width: '100%', maxWidth: '100%', padding: '0 5px 0 28px', boxSizing: 'border-box'}}>
                {Object.entries(viewpoints).map(([key, [value, label]])=>
                    <FormControlLabel
                        key={key}
                        className={'viewpoint' + (value ? ' viewpoint__checked' : '')}
                        control={
                        <Checkbox checked={value} onChange={e => (handleUpdateViewpoint(e, value, key))} name={key} />
                        }
                        label={label}
                    />
                )}
            </FormGroup>
        </FormControl>
    ) 
}