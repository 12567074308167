import React, { useCallback } from 'react';
import {
    Box,
    Typography,
    Card,
    CardContent,
    CardMedia,
} from '@mui/material';

import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next';


// Dropzone and the display of uploaded images
const UploadDropzone = (props) => {
    const { t } = useTranslation();
    const { files, setFiles } = props;

    const onDrop = useCallback(acceptedFiles => {
        setFiles([...files, ...acceptedFiles]);
    }, [setFiles, files])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
    
    const style = {
        my: '10px',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: '10px',
        borderStyle: 'dashed',
        borderColor: 'primary.grey',
        height: '100px',
        display: 'flex',
        cursor: 'pointer',
        color: 'primary.blue',
        justifyContent: 'center',
        transition: 'all 0.5s',
        backgroundColor: (isDragActive ? 'primary.bluegreylight' : 'primary.light2'),
        '&:hover': {
            backgroundColor: 'primary.bluegreylight'
        }
    }
    return (
        <React.Fragment>
            <Box {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <Box sx={style}>
                    <Typography fontWeight={'bold'} sx={{textAlign: 'center'}}>
                        {isDragActive ? t("drop") : t("dragNDrop")}
                    </Typography>
                    <CloudUploadOutlinedIcon sx={{ my: 1, transform: 'scale(1.8)' }} />
                </Box>
            </Box>
            <Box
                sx={{
                    Height: { xs: "27.5vh", md: "27.5vh", xl: '420px' },
                    display: 'flex',
                    flexWrap: 'wrap'
                }}
            >
                {files.map((file) =>
                    <Card key={file.name}
                        sx={{
                            maxWidth: { xs: '49%', sm: '31.3%', md: '19%' },
                            m: '0.5%',
                            overflow: 'hidden'
                        }}>
                        <CardMedia
                            component="img"
                            image={URL.createObjectURL(file)}
                            alt={file.name}
                        />
                        <CardContent>
                            <Typography variant='caption' >
                                {file.name}
                            </Typography>
                        </CardContent>
                    </Card>
                )}
            </Box>
        </React.Fragment>
    )
}

export default React.memo(UploadDropzone);