import React from 'react';
import {
    InputLabel,
    Select,
    OutlinedInput,
    MenuItem,
    ListItemText,
    FormControl,
    Checkbox,
} from '@mui/material';

import { useTranslation } from 'react-i18next';

const Form = (props) => {
    const { name, list, selected, setSelected } = props;

    const { t } = useTranslation();   

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelected(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    return (
        <FormControl sx={{ m: 1, width: '90%' }}>
            <InputLabel >{t(name)}</InputLabel >
            <Select
                multiple
                value={selected}
                onChange={handleChange}
                input={<OutlinedInput label={t(name)} />}
                renderValue={selectedValues => selectedValues.join(', ')}
            >

                {list.map((type) => (
                    <MenuItem key={type} value={type} >
                        <Checkbox checked={selected.indexOf(type) > -1} />
                        <ListItemText primary={type} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}
export default Form;