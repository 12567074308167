import { BrowserRouter as Router } from 'react-router-dom'

import { ThemeProvider } from '@mui/material/styles';
import { Box } from '@mui/material'

import './App.css';
import useToken from './components/tools/useToken'
import Login from './components/pages/Login';
import theme from './theme';
import Routes from './routes'

// Handles routing.
function App() {
  const { token } = useToken();
  const needsLogin = !token && token !== "" && token !== undefined;

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <Box className="App">
          {needsLogin ? <Login /> : <Routes />}
        </Box>
      </ThemeProvider>
    </Router>
  );
}

export default App;