import React from 'react';
import {
    TableCell,
    TableRow,
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import TableRowAction from './TableRowAction';

// Table rows
export default function Row(props) {
    const { row } = props;

    const { t } = useTranslation();

    return (
        <React.Fragment>
            <TableRow
                key={row.id}
                sx={{
                    backgroundColor: row.status === 'ready' ? '#d4edda' : row.status === 'failed' ? '#f8d7da' : row.status === 'sent' ? '#E5F6FD' : '#fff3cd',
                    height: '73px'
                }}>
                <TableCell component="th" scope="row" align='right' width={'10%'}>
                    {row.taskId}
                </TableCell>
                <TableCell align="right" name={new Date(row.date).toISOString()} width={'25%'}>       
                    {(new Date(row.date).toLocaleDateString(t("locale") + '-fi', { year: 'numeric', month: 'short', day: 'numeric' })) + " " +
                        (new Date(row.date).toLocaleTimeString(t("locale") + '-fi', { }).split(".").slice(0, -1).join(':'))} 
                </TableCell>
                <TableCell align="right" width={'25%'}>
                    {row.type}
                </TableCell>
                <TableCell align="right" width={'10%'}>
                    {row.species}
                </TableCell>
                <TableCell align="right" width={'15%'}>
                    {row.status}
                </TableCell>
                <TableCell align="right" width={'15%'}>
                    <TableRowAction row={row} />
                </TableCell>
            </TableRow>
        </React.Fragment >
    )
}