import React from 'react';
import {
    Typography,
    Paper,
} from '@mui/material';

import TableFiltersForm from '../../tools/TableFiltersForm';
import { useTranslation } from 'react-i18next';

const types = ["segmentation", "full", "reidentification", "upload"];
const species = ['norppa', 'unknown'];

console.log(types, species)

// Component with the actual filtering components
const FilteringSystem = (props) => {
    const { selectedSpecies, setSelectedSpecies, selectedTypes, setSelectedTypes } = props;
    const { t } = useTranslation();

    return (
        <Paper
            sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                alignItems: 'center',
                p: 1,
                mt: 3
            }}
        >
            <Typography
                variant='h4'
                color='primary.bluegrey'
                sx={{ mx: 5 }}
            >
                {t("TasksFilters")}
            </Typography>
            <TableFiltersForm name={"type"} list={types} selected={selectedTypes} setSelected={setSelectedTypes} />
            <TableFiltersForm name={"species"} list={species} selected={selectedSpecies} setSelected={setSelectedSpecies} />
        </Paper>
    )
}

export default FilteringSystem;