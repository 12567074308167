// SERVER IP 195.148.20.155:5000

const getUrl = () => {
    // console.log(window.location.hostname)
    return ((window.location.hostname) === "localhost" ? "http://127.0.0.1:5000/" : "https://api.norppaid.com/");
}

var constUrl = getUrl();

export async function fetchData(url, method, token, removeToken, setData) {

    const response = await fetch(constUrl + url, {
        method: method,
        mode: 'cors',
        headers: {
            Authorization: 'Bearer ' + token
        },
    });
    if (response.status === 200) {
        // console.log("SUCCESSS")
        return await response.json();
    } else {
        removeToken();
        window.location.reload();
    }



}


export async function postData(url, method, formData, token, removeToken, setSent, setReason) {

    console.log(constUrl);
    setSent(false);
    await fetch(constUrl + url, {
        method: method,
        mode: 'cors',
        headers: {
            Authorization: 'Bearer ' + token
        },
        body: formData
    })
        .then(response => {
            if (response.status === 200) {
                // console.log("SUCCESSS");
                setReason("New task created succesfully!");
                setSent(false);
            }
            else {
                // console.log("FAILL");
                setReason("Sending task failed.");
                setSent(false);
                removeToken();
                window.location.reload();
            }


            return response.json();
        })
        .then(data => {
            console.log(data);
        })
        .catch(err => {
            setSent(false);
            setReason("Something went wrong... Check console.");

            console.error(err)
        })
}

export async function fetchWithoutToken(url, method) {
    console.log(constUrl + url)
    const response = await fetch(constUrl + url, {
        method: method,
        mode: 'cors',
        crossDomain: true,
    })
    const data = await response.json();
    return data;

}


export async function viewResults(url, method, token) {

    const response = await fetch(constUrl + url, {
        method: method,
        mode: 'cors',
        headers: {
            Authorization: 'Bearer ' + token
        },
    })
    const data = await response.json();
    return data;
}

export async function sendRequest(url, method, token) {
    await fetch(constUrl + url, {
        method: method,
        mode: 'cors',
        headers: {
            Authorization: 'Bearer ' + token
        },
    })
        .then((response) => console.log(response))
}



