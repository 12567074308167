import React, { useState, Suspense } from 'react';
import {
    Container,
    Divider,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import UploadHeader from './UploadHeader';
import UploadDropzone from './UploadDropzone';
import UploadSettings from './UploadSettings';
import UploadSubmit from './UploadSubmit';



// Main upload container
const Upload = () => {
    const [radioValue, setRadioValue] = useState("");
    const [files, setFiles] = useState([]);
    const [database, setDatabase] = useState("");
    const [gender, setGender] = useState("n");
    const [right, setRight] = useState(false);
    const [left, setLeft] = useState(false);
    const [up, setUp] = useState(false);
    const [down, setDown] = useState(false);
    const [front, setFront] = useState(false);
    const [back, setBack] = useState(false);
    const { t } = useTranslation();

    return (
        <Container
            sx={{
                boxShadow: 1,
                borderRadius: 2,
                p: 1,
                width: '90%',
                mx: 'auto'
            }}
        >
            <UploadHeader files={files} setFiles={setFiles} />
            <Divider />
            <UploadDropzone
                files={files}
                setFiles={setFiles}
            />
            <UploadSettings
                radioValue={radioValue}
                setRadioValue={setRadioValue}
                database={database}
                setDatabase={setDatabase}
                gender={gender}
                setGender={setGender}
                viewpoint={{'right':[right, setRight, t("right")], 
                    'left':[left, setLeft, t("left")], 
                    'up':[up, setUp, t("up")], 
                    'down': [down, setDown, t("down")],
                    'front': [front, setFront, t("front")],
                    'back': [back, setBack, t("back")]}
                }
            />
            {radioValue === "Full" || radioValue === "Reidentification" ?
                < UploadSubmit
                    files={files}
                    setFiles={setFiles}
                    radioValue={radioValue}
                    params={{"seal_type": (database === "" ? "norppa" : database), "gender": gender, "viewpoints": {"right": right, "left": left, "up": up, "down": down, "front": front, "back": back}}}
                /> :
                < UploadSubmit
                    files={files}
                    setFiles={setFiles}
                    radioValue={radioValue}
                    params={{}}
                />
            }
        </Container>
    );
}

export default function App() {
    return (
        <Suspense fallback="loading">
            <Upload />
        </Suspense>
    )
}