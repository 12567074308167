import React from 'react';
import {
    Box,
    Typography,
    Button,
    Step,
    Stepper,
    StepLabel,
} from '@mui/material';

import { useTranslation } from 'react-i18next';


// Frontpage stepped instructions
const LinearStepper = () => {

    const { t } = useTranslation();
    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    // Steps and text
    const steps = [
        {
            step: t("step1"),
            desc: t("step1Text"),
        },
        {
            step: t("step2"),
            desc: t("step2Text"),
        },
        {
            step: t("step3"),
            desc: t("step3Text"),
        }
    ]

    return (
        <Box
            sx={{
                width: { xs: '100%', sm: '400px', md: '800px' },
                minWidth: '100%',
            }}
        >
            <Stepper activeStep={activeStep} sx={{
                height: 'auto'}}>
                {steps.map((step, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    return (
                        <Step key={step.step} {...stepProps}>
                            <StepLabel {...labelProps}>{step.step}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {/* Check if all steps have already been visited and show reset if true*/}
            {activeStep === steps.length ? (
                <React.Fragment>
                    <Typography
                        sx={{
                            mt: 2,
                            mb: 1
                        }}
                    >
                        {t("step4")}
                    </Typography>
                    <Typography sx={{height: '100px'}}>
                        {t("step4Text")}
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Box
                            sx={{
                                flex: '1 1 auto'
                            }} />
                        <Button onClick={handleReset}>{t("reset")}</Button>
                    </Box>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <Box>
                        <Typography
                            variant="h4"
                            sx={{
                                mt: 2,
                                mb: 1
                            }}>
                            {t("step")} {activeStep + 1}
                        </Typography>
                    </Box>
                    <Typography
                        variant="body1"
                        sx={{
                            mb: 1,
                            width: '60%',
                            mx: 'auto',
                            height: '100px'
                        }}>
                        {steps[activeStep].desc}
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            pt: 2
                        }}>
                        <Button
                            color="inherit"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                        >
                            {t("back")}
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button onClick={handleNext}>
                            {activeStep === steps.length - 1 ? t("next") :
                                t("next")}
                        </Button>
                    </Box>
                </React.Fragment>
            )}
        </Box>
    );
}

export default LinearStepper;