import React, { Suspense } from 'react';
import {
    Box,
    Container,
    Typography,
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import Upload from '../pages/Upload/index.js';
import LinearStepper from './LinearStepper';

{/* Lower part of the frontpage */ }
const SecondContainer = () => {
    const { t } = useTranslation();
    return (
        <Container
            id="start"
            sx={{
                mt: '85vh',
                minHeight: 'calc(100vh - 105px)',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
            }}>

            <Box>
                <Typography
                    variant="h3"
                    sx={{
                        fontWeight: 'bold',
                        color: 'primary.blue',
                        mt: 10,
                    }}>
                    {t("howItWorks")}
                </Typography>
            </Box>
            <Box sx={{ mt: 5 }}>
                <LinearStepper />
            </Box>
            <Box sx={{ mb: 5 }}>
                <Upload />
            </Box>
        </Container>
    );
}


export default function App() {
    return (
        <Suspense fallback="loading">
            <SecondContainer />
        </Suspense>
    )
}