import React, { Suspense, } from 'react';
import {
    Box,
    CircularProgress
} from '@mui/material';

import SecondContainer from './SecondContainer';
import FirstContainer from './FirstContainer';

// Holds all the stuff in the frontpage
const Content = () => {

    return (
        <Box
            sx={{
                m: 0,
                p: 0,
                minWidth: '100%',
                height: 'auto',
                backgroundColor: '#fbfcfd'
            }}>
            {/* Higher part of the frontpage */}
            <FirstContainer />

            {/* Lower part of the frontpage */}
            <SecondContainer />
            {/* <Footer /> */}
        </Box >
    );
}


export default function App() {
    return (
        <Suspense fallback={<CircularProgress />}>
            <Content />
        </Suspense>
    )
}