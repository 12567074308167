export function loadImage(url) {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = () => reject(new Error(`load ${url} fail`));
        img.src = url;
    });
};

// Draw plain image
export const draw = async (context, url, options) => {

    return loadImage(url).then(img => {
        let width = img.naturalWidth;
        let height = img.naturalHeight;
        while ((width > 500) || (height > 500)) {
            width = width / 2;
            height = height / 2;
        }
        context.globalAlpha = 1;
        context.drawImage(img, 0, 0, width, height);
        
    });
};

// Draw database image with highlights
export const drawHighlight = async (context, url, options, set) => {
    await loadImage(url).then(img => {
        let width = img.naturalWidth;
        let height = img.naturalHeight;
        while ((width > 500) || (height > 500)) {
            width = width / 2;
            height = height / 2;
        }
        
        context.drawImage(img, 0, 0, width, height);
        const index = set === 'db' ? 1 : 0
        options[index].map((opt, i) => {
            return loadImage("Highlight.png").then(img => {
                context.globalAlpha = options[2][i] * 2;
                const fixedOpt = opt.map(element => element * width);
                context.drawImage(img, fixedOpt[0] - fixedOpt[2] / 2, fixedOpt[1] - fixedOpt[3] / 2, fixedOpt[2], fixedOpt[3]);
            });
        })
    });
    
};

export const sortedRowInformation = (rowArray, comparator) => {
    const stabilizedRowArray = rowArray.map((el, index) => [el, index]);
    stabilizedRowArray.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return (order)
        }
        return (
            a[1] - b[1]
        )
    })
    return stabilizedRowArray.map((el) => el[0])

}

// Compares using customsort function
export function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => customSort(a, b, orderBy)
        : (a, b) => -customSort(a, b, orderBy)
}

// Does the final comparison between two datas
function customSort(a, b, orderBy) {
    var collator = new Intl.Collator([], { ignorePunctuation: true });
    return (collator.compare(a[orderBy], b[orderBy]));
}


// Function that handles the data filtering
export function filterTaskData(data, selectedTypes, selectedSpecies) {

    const filteredData = [];
    data.map((el) => {
        if (selectedTypes.includes(el.type) && selectedSpecies.includes(el.species)) {
            filteredData.push(el);
        }
        
        
        return true;
    })
    return filteredData;
}

    

function isInViewpoints(viewpoint, inputViewpoints) {
    if (viewpoint === "unknown") {
        if (Object.values(inputViewpoints).every((x) => x === false)) {
            return true
        }
        return false;
    }
    return inputViewpoints[viewpoint] === true ;
  }


export function filterDBData(data, selectedViewpoints, selectedGenders) {

    const filteredData = [];
    const genderMatch = {"f": "female",
                        "m": "male",
                        null: "unknown",
                        "n": "unknown"}
    
    data.map((el) => {
        if (selectedGenders.includes(genderMatch[el.gender])) {
            const new_el = {...el}
            const filtered_images = el.images.filter((image) => selectedViewpoints.some((v) => isInViewpoints(v, image.viewpoints)));
            new_el.images = filtered_images;
            filteredData.push(new_el);
        }
        return true;
        
    })
    return filteredData;
}

export function getUrl() {
    // SERVER IP 195.148.20.155:8888
    return ((window.location.hostname) === "localhost" ? "http://127.0.0.1:8888" : "https://static.norppaid.com");
    // return("http://195.148.20.155:8888")
}