import Particles from "react-tsparticles";
import {
   Box,
} from '@mui/material';


// Animated background behind Tasks and Database page.
// Documentation can be found at https://github.com/matteobruni/tsparticles
const ParticleBackground = () => {
   return (
      <Box>
         <Particles
            height={10}
            width={100}
            options={{
               detectRetina: true,
               fpsLimit: 144,
               interactivity: {
                  detectsOn: "canvas",
                  events: {
                     onHover: {
                        enable: true,
                        mode: "grab"
                     },
                     onClick: {
                        enable: true,
                        mode: "repulse"
                     },
                     resize: true
                  },
                  modes: {
                     grab: {
                        distance: 100,
                        line_linked: {
                           opacity: 1
                        }
                     },
                     bubble: {
                        distance: 500,
                        size: 4,
                        duration: 2,
                        opacity: 0.4,
                        speed: 3
                     },
                     repulse: {
                        distance: 100,
                        duration: 0.4
                     },
                     push: {
                        particles_nb: 4,
                     },
                     remove: {
                        particles_nb: 20
                     }
                  }
               },
               particles: {
                  color: {
                     value: "#000000",
                     animation: {
                        enable: true,
                        speed: 20,
                        sync: true
                     }
                  },
                  lineLinked: {
                     color: "#000",
                     distance: 150,
                     enable: true,
                     opacity: 0.4,
                     width: 0.5
                  },
                  move: {
                     attract: {
                        enable: false,
                        rotate: {
                           x: 600,
                           y: 1200
                        }
                     },
                     bounce: false,
                     direction: "none",
                     enable: true,
                     outMode: "bounce",
                     random: false,
                     speed: 0.5,
                     straight: false
                  },
                  number: {
                     density: {
                        enable: false,
                        area: 2000
                     },
                     limit: 0,
                     value: 40
                  },
                  opacity: {
                     animation: {
                        enable: true,
                        speed: 1,
                        opacity_min: 0.1,
                        sync: false
                     },
                     random: false,
                     value: 0.5
                  },
                  shape: {
                     type: "circle"
                  },
                  size: {
                     animation: {
                        enable: false,
                        minimumValue: 0.1,
                        speed: 40,
                        sync: false
                     },
                     random: true,
                     value: 3
                  }
               }
            }}
         />
      </Box>
   )

}

export default ParticleBackground;