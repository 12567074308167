import React, { useState, useEffect } from 'react';
import {
    IconButton,
    Collapse, Box,
    Typography,
    Button,
    Divider,
    TableCell,
    TableRow
} from '@mui/material';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { sendRequest } from '../../tools/Fetch';
import useToken from '../../tools/useToken';

import { useTranslation } from 'react-i18next';
import DBModal from './DBModal';
import DBUpload from './DBUpload';
import DBMove from './DBMove';
import MediaCard from './MediaCard';
import Gender from './Gender'



const Row = (props) => {
    const { row, id, species, setData } = props;
    const { t } = useTranslation();
    const { token } = useToken();
    

    const [open, setOpen] = useState(false);
    const [gender, setGender] = useState(row.gender === null ? "n" : row.gender);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [showMoveModal, setShowMoveModal] = useState(false);
    const [images, setImages] = useState([]);
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        setImages(row.images);
    }, [row])

    useEffect(() => {
        setGender(row.gender === null ? "n" : row.gender);
    }, [row.gender])

    const handleModalOpen = (e) => {
        e.stopPropagation();
        setShowUploadModal(true);
    }

    const handleMoveModalOpen = (e) => {
        e.stopPropagation();
        setShowMoveModal(true);
    }

    const handleGenderChange = (e) => {
        e.stopPropagation();
        sendRequest(("update_gender?seal_id=" + row.SealID + "&gender=" + e.target.value), 'POST', token)
            .then(() => {
                setGender(e.target.value);
            })
        
      };
    


    return (
        <React.Fragment>
            <TableRow
                key={id}
                sx={{
                    cursor: 'pointer',
                    '&:hover': {
                        backgroundColor: 'primary.light2'
                    }
                }}
                onClick={() => {
                    console.log("click");
                    setOpen(!open);
                }
                    
                }
            >
                <TableCell>
                    <IconButton aria-label="expand row" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="td" scope="row">
                    {row.SealID}
                </TableCell>
                <TableCell component="td" scope="row">
                    {row.name}
                </TableCell>
                <TableCell component="td" scope="row">
                    <Gender gender={gender} handleGenderChange={handleGenderChange}/>
                    
                </TableCell>
                <TableCell component="td" scope="row">
                    {images.length}
                </TableCell>
            </TableRow>

            <TableRow>
                <TableCell style={{ padding: 0 }} colSpan={5}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ display: 'flex', alignItems: 'center', m: 1 }}>
                            <Typography
                                sx={{
                                    flexGrow: 1,
                                    ml: 2,
                                    fontWeight: 'bold',
                                    color: 'primary.blue'
                                }}>
                                {t("uploadFiles")}
                            </Typography>
                            {species === "unknown" ?
                                <Button 
                                    variant="contained" 
                                    color="secondary" 
                                    onClick={handleMoveModalOpen} 
                                    sx={{ marginRight: "20px" }}
                                    startIcon={<ContentPasteGoIcon />}
                                >
                                    {t("move")}
                                </Button> :
                                null
                            }
                            <Button variant="contained" onClick={handleModalOpen} startIcon={<CloudUploadIcon /> }>
                                {t("upload")}
                            </Button>
                            <DBModal open={showMoveModal} setOpen={setShowMoveModal} >
                                <DBMove sealID={row.SealID} species={species} sealName={row.name} setModalOpen={setShowMoveModal} setData={setData}/>
                            </DBModal>
                            
                            <DBModal open={showUploadModal} setOpen={setShowUploadModal} >
                                <DBUpload sealID={row.SealID} sealGender={gender} species={species} sealName={row.name}/>
                            </DBModal>
                        </Box>
                        <Divider />
                        <Typography sx={{ mx: 3, my: 1, color: 'primary.dark1', fontWeight: 'bold' }}>
                            {t("Images")}
                        </Typography>
                        <Box
                            sx={{ m: 1, display: 'flex', flexWrap: 'wrap' }}>
                            {images.map((img, index) => (
                                <MediaCard
                                    key={img.id}
                                    img={img}
                                    index={index}
                                    images={images}
                                    setImages={setImages}
                                    checked={checked}
                                    setChecked={setChecked}
                                    species={species} 
                                    sealID = {row.SealID}
                                    viewpoint={img.viewpoint}/>
                            ))}
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment >
    )
}

export default Row;