import React from 'react';
import {
    Modal,
    Box,
    IconButton,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';



const DBModal = (props) => {
    const { open, setOpen, children } = props;
    // handle
    return (
        <React.Fragment>
            {/* {console.log(row)} */}
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                onClick={(e) => e.stopPropagation()}
                sx={{ overflow: 'scroll' }}
            >
                <Box sx={{
                    position: 'absolute',
                    left: '50%',
                    top: '20%',
                    width: '40%',
                    maxWidth: '1000px',
                    minWidth: '250px',
                    transform: 'translate(-50%, 0%)',
                    bgcolor: 'background.paper',
                    boxShadow: 5,
                    borderRadius: 1,
                    p: 2,

                }}>
                    <React.Fragment>
                        <IconButton sx={{
                            display: 'flex',
                            ml: 'auto',
                            mr: '2%'
                        }}
                            onClick={() => setOpen(false)}>
                            <CloseIcon color="error" />
                        </IconButton>
                        {children}

                    </React.Fragment>
                </Box>
            </Modal>
        </React.Fragment >
    )
}

export default DBModal;