import React, { useState } from 'react';
import {
    Box,
    Table,
    TableBody,
    TableContainer,
    Paper,
} from '@mui/material';

import { getComparator, sortedRowInformation, filterTaskData } from "../../tools/functions";
import Pagination from "../Database/Pagination";
import TableFilters from './TableFilters';
import TableHeader from './TableHeader';
import TableRow from './TableRow';

// Main container for the table.
const TableContent = (props) => {
    const { data } = props;
    const [orderDirection, setOrderDirection] = useState('asc');
    const [valueToOrderBy, setValueToOrderBy] = useState('date');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10)

    // Filters
    const [selectedSpecies, setSelectedSpecies] = useState(['norppa', 'unknown']);
    const [selectedTypes, setSelectedTypes] = useState(["segmentation", "full", "reidentification", "upload"]);

    return (
        <Box sx={{  }}>
            <TableFilters
                selectedSpecies={selectedSpecies}
                setSelectedSpecies={setSelectedSpecies}
                selectedTypes={selectedTypes}
                setSelectedTypes={setSelectedTypes}
            />
            <TableContainer component={Paper} sx={{ mt: 5 }}>
                <Table aria-label="simple table" sx={{ minWidth: 350 }}>
                    <TableHeader
                        valueToOrderBy={valueToOrderBy}
                        setValueToOrderBy={setValueToOrderBy}
                        orderDirection={orderDirection}
                        setOrderDirection={setOrderDirection}
                    />
                    <TableBody>
                        {
                            sortedRowInformation(filterTaskData(data,  selectedTypes, selectedSpecies), getComparator(orderDirection, valueToOrderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => (
                                    <TableRow key={row.taskId} row={row} />
                                ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <Pagination
                data={data}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
            />
        </Box>
    )
}

export default TableContent;