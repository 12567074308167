import { useState } from 'react';

// Login created using this guide right here 
// https://dev.to/nagatodev/how-to-add-login-authentication-to-a-flask-and-react-application-23i7
function useToken() {

  const getToken = () => {
    const userToken = localStorage.getItem('token');
    return userToken && userToken
  }

  const [token, setToken] = useState(getToken());

  const saveToken = (userToken) => {
    localStorage.setItem('token', userToken);
    setToken(userToken);
  };

  const removeToken = () => {
    localStorage.removeItem("token");
    setToken(null);
  }

  return {
    saveToken,
    token,
    removeToken
  }

}

export default useToken;