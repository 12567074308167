import React, { useState } from 'react';
import {
    Snackbar,
    Typography,
    Button,
    Alert
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import SendIcon from '@mui/icons-material/Send';

import { useTranslation } from 'react-i18next';
import { postData } from '../../tools//Fetch';
import useToken from '../../tools//useToken';
import JSZip from 'jszip';

const negativeMessages = ["You need to add files.", 
    "You need to choose method.", 
    "Something went wrong... Check console.", 
    "Sending task failed.",
    "You need to specify seal id",
    "You need to choose at least 1 viewpoint, but not more than 3"
];

const App = (props) => {
    const { files, setFiles, radioValue, params } = props;
    const { t } = useTranslation();
    const { token, removeToken } = useToken();

    const [showSnackbar, setShowSnackbard] = useState(false);
    const [sent, setSent] = useState(false);
    const [reason, setReason] = useState("");

    const handleClick = () => {
        if (files.length === 0) {
            setReason("You need to add files.");
            setShowSnackbard(true);
        } else if (radioValue === "") {
            setReason("You need to choose method.");
            setShowSnackbard(true);
        } else if ("seal_id" in params && (params.seal_id === "" || typeof params.seal_id == "undefined")) {
            setReason("You need to specify seal id");
            setShowSnackbard(true);
        } else if ("viewpoints" in params && [0,6].indexOf(Object.values(params.viewpoints).reduce((a, b) => a + b, 0)) >= 0) {
            setReason("You need to choose at least 1 viewpoint, but not more than 3");
            setShowSnackbard(true);
        } else {
            setShowSnackbard(true);
            setReason("Sending files...");
            setSent(true);
            createZip();
        }
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowSnackbard(false);
    };

    const createZip = () => {
        var formData = new FormData();
        var zip = new JSZip();

        for (let i = 0; i < files.length; i++) {
            let filename = files[i].name;
            zip.file(filename, files[i])
        }

        zip.generateAsync({ type: 'blob' }).then((blobdata) => {
            // create zip blob file
            let zipblob = new Blob([blobdata])
            formData.append("archive", zipblob);
            formData.append('task_type', radioValue);
            formData.append('params', JSON.stringify(params));
            postData("send_task", "POST", formData, token, removeToken, setSent, setReason);
        })
            .then(() => {

                formData = new FormData();
                setFiles([]);
            })

    }

    return (
        <React.Fragment>
            <Button
                variant="contained"
                size="large"
                sx={{ display: 'flex', margin: '0 10px 15px'}}
                onClick={handleClick}>
                {sent
                    ?
                    <CircularProgress size={30} sx={{ color: 'primary.dark1' }} />
                    :
                    <React.Fragment>
                        <Typography>
                            {t("send")}
                        </Typography>
                        <SendIcon sx={{ ml: 1 }} />
                    </React.Fragment>
                }
            </Button>
            <Snackbar open={showSnackbar} autoHideDuration={6000} onClose={handleClose}>
                <Alert elevation={6} variant="filled" onClose={handleClose} severity={negativeMessages.includes(reason) ? "error" : "success"} sx={{ width: '100%' }}>
                    {reason}
                </Alert>
            </Snackbar>

        </React.Fragment>
    )


}

export default App;