import React, { useState } from 'react';
import {
    Typography,
    Box,
    Divider
} from '@mui/material';

import { draw, drawHighlight, getUrl } from '../../tools/functions';
import { useTranslation } from 'react-i18next';
import Canvas from '../../tools/Canvas';

// Handles result showing for full or re-identification types. Highlights the spots using Canvas
const ShowResults = ({ result }) => {
    const { t } = useTranslation();


    const [clickedID, setClickedID] = useState("");
    // const [queryID, setQueryID] = useState("");

    const handleClick = (index) => {
        if (index === clickedID) {
            setClickedID("");
        } else {
            setClickedID(index);
        }

    }

    return (
        <React.Fragment>
            <Typography variant='h4'
                sx={{ textAlign: 'center' }}>
                {t("resultTitle")}
            </Typography>
            <Typography
                variant='body2'
                sx={{ textAlign: 'center', marginBottom: "20px" }}>
                *{t("resultInfo")}
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row' }}>
            {result.map((res, ind) => {
                return (res.query.map((query, index) => 
                        <Box key={index}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', marginRight: '30px', marginBottom: '30px'}}>
                            <Typography sx={{ textAlign: 'center' }}>
                                top-{index + 1}: {res.labels[index]}
                            </Typography>
                                    <Box>
                                        <Canvas
                                            draw={clickedID !== index ? draw : drawHighlight}
                                            img={getUrl() + query.split("public")[1]}
                                            options={res.patch_matches[index]}
                                            onClick={() => handleClick(index)}
                                            set = {'query'}
                                        /> 
                                        <Divider sx={{ my: 2 }} />
                                        <Canvas
                                            draw={clickedID !== index ? draw : drawHighlight}
                                            img={getUrl() +  '/database/' + res.db[index]}
                                            options={res.patch_matches[index]}
                                            onClick={() => handleClick(index)} 
                                            set = {'db'}
                                        />
                                    </Box>   
                            </Box>
                        </Box>)
                )
            })}
            </Box>
        </React.Fragment>
    )
}

export default ShowResults;