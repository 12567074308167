import React from 'react';
import {
    Modal,
    Box,
    IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const Component = (props) => {
    const { open, setOpen, inside, style } = props;

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            onClick={(e) => e.stopPropagation()}
            sx={{ overflow: 'scroll' }}
        >
            <Box sx={style
                ?
                style
                :
                {
                    position: 'absolute',
                    left: '50%',
                    width: '80%',
                    top: '10%',
                    maxWidth: '1000px',
                    transform: 'translate(-50%, 00%)',
                    bgcolor: 'background.paper',
                    border: '1px solid #000',
                    boxShadow: 24,
                    borderRadius: 2,
                    p: 2,
                }
            }>
                <IconButton sx={{
                    display: 'flex',
                    ml: 'auto',
                    mr: '2%'
                }}
                    onClick={() => setOpen(false)}>
                    <CloseIcon color="error" />
                </IconButton>
                {inside}
            </Box>
        </Modal>
    )

}

export default Component;