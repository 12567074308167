import React from 'react';
import {
    FormControl,
    FormLabel, 
    Select,
    MenuItem
} from '@mui/material';
import MaleIcon from '@mui/icons-material/Male';
import FemaleIcon from '@mui/icons-material/Female';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { useTranslation } from 'react-i18next';
import { pink, blue, grey } from '@mui/material/colors';


export default function Viewpoint(props) {
    const {gender, handleGenderChange, isHelper} = props;
    const { t } = useTranslation();
    

    return (
        <FormControl sx={{ m: 1, width: '100px'}}>
            {isHelper?<FormLabel sx={{ textAlign: 'left' }} component="legend">{t('sealGender')}</FormLabel>: null}
            <Select
                value={gender}
                onChange={handleGenderChange}
                onClick={(e) => e.stopPropagation()}
            >

                <MenuItem key={"m"} value={"m"}><MaleIcon sx={{ color: blue[500] }}/></MenuItem>
                <MenuItem key={"f"} value={"f"}><FemaleIcon sx={{ color: pink[500]}}  /></MenuItem>
                <MenuItem key={"n"} value={"n"}><QuestionMarkIcon sx={{ color: grey[500]}}  /></MenuItem>
                
            </Select>
    </FormControl>
    ) 
}