import React from 'react';
import PropTypes from 'prop-types';
import { loadImage } from "./functions";

// Draws image to the canvas using the declared draw function.
// Uses HTML5 canvas element as canvas
const Canvas = ({ draw, img, options, set, onClick }) => { // CHANGED
    const canvas = React.useRef();
    const [imageWidth, setImageWidth] = React.useState(0);
    const [imageHeight, setImageHeight] = React.useState(0);

    React.useEffect(() => {
        const context = canvas.current.getContext('2d');
        loadImage(img).then(image => {
            let width = image.naturalWidth;
            let height = image.naturalHeight;
            while ((width > 500) || (height > 500)) {
                width = width / 2;
                height = height / 2;
            }
            setImageWidth(width);
            setImageHeight(height);
        })
        draw(context, img, options, set);
    });

    return (
        <canvas
            style={{
                cursor: (onClick ? 'pointer' : 'default')
            }}
            ref={canvas}
            width={imageWidth}   // CHANGED
            height={imageHeight} // CHANGED
            onClick={onClick}
        />
    )
}

// Requires draw function
Canvas.propTypes = {
    draw: PropTypes.func.isRequired,
};

export default Canvas;