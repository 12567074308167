import React from 'react';
import {
    Typography,
    Button,
    MenuItem,
    ClickAwayListener,
    Popper,
    Paper,
    Grow,
    MenuList,
} from '@mui/material';
import { Link as Linked } from 'react-router-dom';

// Database button popper menu
const DatabaseMenu = (props) => {
    const { open, anchorRef, setOpen, setBurger } = props;

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
        setBurger(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
            setBurger(false);
        } else if (event.key === 'Escape') {
            setOpen(false);
            setBurger(false);
        }
    }
    return (
        <Popper
            open={open}
            anchorEl={anchorRef.current}
            placement="bottom"
            role={undefined}
            transition
            disablePortal={true}
            style={{zIndex: 5}}
        >
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin:
                            placement === 'bottom-start' ? 'left top' : 'left bottom',
                    }}
                >
                    <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                                autoFocusItem={open}
                                onKeyDown={handleListKeyDown}

                            >
                                <MenuItem onClick={handleClose} sx={{ p: 0, m: 0 }}>
                                    <Button
                                        component={Linked}
                                        to={"/database/Saimaa"}
                                        sx={{ color: 'text.primary', px: 2 }}>
                                        <Typography variant='body1'>
                                            Saimaa
                                        </Typography>
                                    </Button>
                                </MenuItem>
                                <MenuItem onClick={handleClose} sx={{ p: 0, m: 0 }}>
                                    <Button
                                        component={Linked}
                                        to={"/database/Unknown"}
                                        sx={{ color: 'text.primary', px: 2 }}>
                                        <Typography variant='body1'>
                                            Unknown
                                        </Typography>
                                    </Button>
                                </MenuItem>
                                {/* Optional Ladoga ringed seal  */}
                                {/* <MenuItem onClick={handleClose} sx={{ p: 0, m: 0 }}>
                            <Button
                               component={Linked}
                               to={"/database/Ladoga"}
                               sx={{ color: 'text.primary', px: 2 }}>
                               <Typography variant='body1'>
                                  Ladoga
                               </Typography>
                            </Button>
                         </MenuItem> */}
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    )
}

export default DatabaseMenu;