import React, { Suspense, useState } from 'react';
import useToken from '../tools/useToken'
import {
  FormControl,
  Box,
  Button,
  TextField,
  Typography,
} from '@mui/material';
import { fetchWithoutToken } from '../tools/Fetch';


// Login created using this guide right here 
// https://dev.to/nagatodev/how-to-add-login-authentication-to-a-flask-and-react-application-23i7
function Login() {
  const { saveToken } = useToken();

  const [error, setError] = useState("");
  const [loginForm, setloginForm] = useState({
    username: "",
    password: ""
  })

  function logMeIn() {
    const url = "login?username=" + loginForm.username + "&password=" + loginForm.password;
    
    fetchWithoutToken(url, 'POST')
      .then(data => {
        console.log(data);
        if (data.err) {
          console.log(data.err);
          setError(data.err);
        }
        else if (data.access_token) {
          setError("");
          console.log(data.access_token);
          saveToken(data.access_token);
          window.location.reload();
        }
      })
  }

  const handleChange = (event) => {
    const { value, name } = event.target

    setloginForm({
      ...loginForm,
      [name]: value
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (loginForm.username && loginForm.password) {
      logMeIn();
      setError("");
    } else {
      setError("Username or password can't be empty.")
    }
  }

  return (
    <Box
      sx={{
        display: "flex", alignItems: "center", justifyContent: "center", flexDirection: 'column', height: 'calc(100vh - 50px)'
      }}
    >
      <Typography variant='h3'>
        NorppaID
      </Typography>
      <FormControl component="form" onSubmit={handleSubmit}>
        <TextField
          onChange={handleChange}
          text={loginForm.username}
          value={loginForm.username}
          type="username"
          name="username"
          label="Username"
          sx={{ m: 1 }}
        />

        <TextField
          onChange={handleChange}
          text={loginForm.password}
          value={loginForm.password}
          type="password"
          name="password"
          label="Password"
          sx={{ m: 1 }}
        />
        <Box>
          <Button
            type="submit"
            variant="contained">
            Submit
          </Button>
        </Box>
        <Typography color={'error'}>
          {error}
        </Typography>
      </FormControl>
    </Box>
  )
}


export default function App() {
  return (
    <Suspense fallback="loading">
      <Login />
    </Suspense>
  )
}