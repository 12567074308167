import React from 'react';
import {
    TablePagination,
} from '@mui/material';

import { useTranslation } from 'react-i18next';

// Table Pagination
const Pagination = (props) => {
    const { data, page, setPage, rowsPerPage, setRowsPerPage } = props;
    const { t } = useTranslation();

    // Pagination
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        if (event.target.value === 'all') {
            setRowsPerPage(data.length);
        }
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    return (

        <TablePagination
            component="div"
            count={data.length}
            page={page}
            sx={props.rowsPerPage > 10 ? { mb: 3 } : { mb: 0 }}
            rowsPerPageOptions={data.length > 100 ? [10, 25, 50, 100, data.length] : [10, 25, 50, 100]}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={t("PaginationLabel")}
        />
    )
}

export default Pagination;