import React, { useEffect, useState, Suspense } from 'react';
import {
    Container,
    Box,
    CircularProgress
} from '@mui/material';

import ParticleBackground from '../../ParticleBackground';
import TableContent from './TableContent';
import { fetchData } from '../../tools/Fetch';
import useToken from '../../tools/useToken';




/* Main Component */
const Database = () => {
    const { token, removeToken } = useToken();
    const [data, setData] = useState([])

    // Initial species
    const [species, setSpecies] = useState("");
    const site = window.location.href.split("/").pop().toLowerCase();

    // Change species depending on which page we at .../database/Saimaa for Saimaa and .../database/Unkown for Unkown
    useEffect(() => {
        setSpecies((site === "saimaa" ? "norppa" : site));
    }, [setSpecies, site])


    useEffect(() => {

        fetchData("get_database?seal_type=" + species, 'GET', token, removeToken, setData)
            .then(data => {
                setData(data);
            })
            .catch(err => console.error(err));
        // setInterval(fetchData("get_database?seal_type=norppa", 'GET', token, removeToken, setData), 180000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [species])

    return (
        <Box sx={{
            minHeight: 'calc(100vh - 183px)',
            width: '100%',
            marginTop: '80px',
            display: 'flex',
            justifyContent: 'space-between',
        }}>
            {/* {console.log(data)} */}
            <Container sx={{ zIndex: 1 }}>
                <TableContent data={data} species={species} setData={setData}/>
            </Container>
            <ParticleBackground />
        </Box>
    );
}
export default function App() {
    return (
        <Suspense fallback={<CircularProgress />}>
            <Database />
        </Suspense>
    )
}