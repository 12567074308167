import React, { useState } from 'react';
import {
    Typography,
    IconButton,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import ZoomInIcon from '@mui/icons-material/ZoomIn';

import { useTranslation } from 'react-i18next';
import { viewResults } from '../../tools//Fetch';
import ShowResults from './ShowResults';
import useToken from '../../tools//useToken';
import { getUrl } from '../../tools/functions';
import CustomModal from '../../tools//Modal';

const typeGroup1 = ["segmentation", "classification"];
const typeGroup2 = ["reidentification", "full", "re-identification"];

const CheckStatus = ({ row }) => {
    const { t } = useTranslation();
    const { token } = useToken();


    const [open, setOpen] = useState(false);
    const [results, setResults] = useState([]);

    const getResults = (id) => {
        viewResults(("view_result?task_id=" + id), 'POST', token, setResults)
            .then(data => {
                setResults(data);
                setOpen(true);
            })
    }

    const downloadResults = (id) => {
        viewResults(("download_result?task_id=" + id), 'POST', token, setResults)
            .then(data => {
                setResults(data);
                window.open(getUrl() + data.link, '_blank').focus();
            })
    }

    // Task is ready and type is "segmentation" or "classification"
    if (row.status === "ready" && typeGroup1.includes(row.type)) {
        return (
            <IconButton
                onClick={() => downloadResults(row.taskId)}>
                <DownloadIcon />
            </IconButton>
        )

        // Task is ready and type is "reidentification" or "full"
    } else if (row.status === "ready" && typeGroup2.includes(row.type)) {
        return (
            <React.Fragment>
                <IconButton onClick={() => getResults(row.taskId)}>
                    <ZoomInIcon />
                </IconButton>
                <CustomModal open={open} setOpen={setOpen} inside={<ShowResults result={results} />} />
            </React.Fragment>
        )

        // Task is still in progress
    } else if (row.status === "in progress") {
        return (
            <Typography variant='caption'>
                {t("processed")} {row.processed}
            </Typography>
        )

        // Task failed
    } else {
        return (
            <>
            </>
        )
    }
}
export default CheckStatus;