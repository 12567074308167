import { createTheme } from '@mui/material/styles';


const theme = createTheme({
    typography: {
        button: {
            textTransform: 'none'
        },
        fontFamily: [
            "Poppins",
            'sans-serif'
        ].join(','),
    },
    components: {
        // Name of the component
        MuiButtonBase: {
            defaultProps: {
                // The props to apply
                disableRipple: true, // No more ripple, on the whole application 💣!
            },
        },
    },

});
export default theme;