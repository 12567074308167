const style = {
    mx: 0.5,
    fontWeight: 'bold',
    color: '#131919',
    height: 'auto',
    transition: 'all 375ms ease',
    '&:hover': {
        color: 'primary.main',
        background: 'transparent',
    }
};

export default style;