import React, { Suspense } from 'react';
import {
    Box,
    Container,
    Typography,
    Link,
    IconButton
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { useTranslation } from 'react-i18next';

{/* Lower part of the frontpage */ }
const SecondContainer = () => {
    const { t } = useTranslation();
    return (
        <Container sx={{ height: '10vh' }}>
            <Box className='bg-image' />
            <Box sx={{
                display: 'flex',
                height: { xs: '92vh', sm: '88vh', md: '84vh' },
                flexDirection: 'column',
                justifyContent: 'space-between',
                position: 'absolute',
                marginTop: { xs: 5, sm: 10, md: 20 },
                left: 0,
                right: 0,
                mx: 'auto',
                borderRadius: 1,

            }}>
                <Box sx={{
                    textAlign: 'center',
                    width: { xs: '95%', sm: '70%', md: '60%' },
                    mt: { xs: '7vh', sm: '5vh', md: '3vh' },
                    mx: 'auto',
                }}>
                    <Typography variant='h3'
                        sx={{
                            color: 'primary.light3',
                            textShadow: '0 4px 6px black',
                            fontFamily: 'Whyte,arial,sans-serif'
                        }}>
                        {t("title")}
                    </Typography>
                    <Typography variant='body1'
                        sx={{
                            color: '#c1ffff',
                            fontWeight: 'bold',
                            textShadow: '0 0 4px black',
                            my: 3
                        }}>
                        {t("subtitle")}
                    </Typography>
                </Box>

                {/* Get started button */}
                <Link
                    href="#start"
                    sx={{
                        textDecoration: 'none',
                        color: 'background.grey',
                    }}>
                    <IconButton
                        size='large'
                        sx={{
                            mx: 'auto',
                            flexDirection: 'column',
                            '&:hover': {
                                backgroundColor: 'transparent',
                            },

                            zIndex: 3,
                        }}>
                        {t("start")}
                        <KeyboardArrowDownIcon className='icon' />
                    </IconButton>
                </Link>
            </Box>
        </Container>
    );
}


export default function App() {
    return (
        <Suspense fallback="loading">
            <SecondContainer />
        </Suspense>
    )
}