// /src/routes.js
import { Routes, Route } from 'react-router-dom'
import { Box, Typography } from '@mui/material'

import Home from './components/Home/index';
import Database from './components/pages/Database/index.js';
import Tasks from './components/pages/Tasks/index.js';
// import Login from './components/pages/Login';
import Navbar from './components/Navbar/index';
import Footer from './components/Footer';

function AppRoutes() {
    return (
        <Box
            sx={{
                // display: 'flex',
                flexDirection: 'column',
                // justifyContent: 'space-evenly'
                height: 'calc(100vh - 180px)'
            }}>
            <Navbar />
            <Routes>
                <Route path="/" element={<Home />} />
                {/* <Route path="/Login" element={<Login />} /> */}
                <Route path="/Database/Saimaa" element={<Database />} />
                <Route path="/Database/Unknown" element={<Database />} />
                <Route path="/Tasks" element={<Tasks />} />
                <Route
                    path="*"
                    element={
                        <Box sx={{ padding: "1rem", color: 'black', height: 'calc(100vh - 101px)' }}>
                            <Typography my={10}>There's nothing here!</Typography>
                        </Box>
                    }
                />

            </Routes>
            <Footer />
        </Box>
    );
}

export default AppRoutes;