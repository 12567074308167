import React, { Suspense } from 'react';
import {
    BottomNavigation,
    Typography,
    Box,
    Container

} from '@mui/material';


// Footer of the website
const Footer = () => {
    return (


        <Box
            sx={{
                backgroundColor: 'rgba(118,157,188, 0.3)',
                borderTop: '3px solid',
                borderColor: 'primary.cyan',
                width: '100%',
                position: 'absolute',
                left: 0
            }}>
            <Container
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    height: '100px',
                }}>
                <Box>
                    <img src={process.env.PUBLIC_URL + "/LUT.png"} className='LUT' alt='LUT' />
                    <img src={process.env.PUBLIC_URL + "/coexist.png"} className='Coexists' alt='Coexists' />
                </Box>
                <img src={process.env.PUBLIC_URL + "/cbc.png"} className='cbc' alt='cbc' />
            </Container>
            {/* <Box sx={{
                zIndex: 2,
                // width: '100%',
                borderTop: '3px solid',
                borderColor: 'primary.cyan',
            }}> */}
            {/* <Typography
                    variant='body2'
                    color={'black'}
                    sx={{
                        fontWeight: 'bold',
                        // width: '100px',
                        // display: 'flex',
                        // textAlign: 'center',
                        justifyContent: 'center',
                        // alignItems: 'center',
                        alignSelf: 'center',
                    }}
                > */}
            {/* {new Date().getUTCFullYear()} */}
            {/* </Typography> */}

            {/* </Box> */}
        </Box >
    );
};



export default function App() {
    return (
        <Suspense fallback="loading">
            <Footer />
        </Suspense>
    )
}